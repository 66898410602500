




import { Component, Vue } from 'vue-property-decorator';
import AuthApplicationForm from '@/app/modules/auth/components/AuthApplicationForm.vue';

@Component({
  components: {
    AuthApplicationForm,
  },
})
export default class AuthApplicationView extends Vue {
  /**
   * Getters
   */
  private get nip(): string | undefined {
    return this.$route.query.nip as string;
  }
}
